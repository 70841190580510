.logo {
  display: inline-block;
  color: $pink_color;
  font-size: 38px;
  line-height: 38px;
  padding: 20px 0 10px 100px;
  background: url('../img/logo.png') no-repeat 0 50%;
  background-size: 80px;
  @media(min-width: $xl) {
    font-size: 46px;
    line-height: 46px;
    padding: 40px 0 30px 140px;
    background-size: 120px; }

  span {
    color: $yellow_color; } }

.phone {
  color: $gray_color;
  font-size: 25px;
  line-height: 25px;
  @media(min-width: $xl) {
    font-size: 33px; } }

.cart {
  display: inline-block;
  vertical-align: middle;
  padding: 20px 100px 20px 0;
  background: url('../img/cart.png') no-repeat 100% 50%;

  &.bottle1 {
    background: url('../img/cart.png') no-repeat 100% 50%, url('../img/bottle1.png') no-repeat 82% 35%; }

  &.bottle2 {
    background: url('../img/cart.png') no-repeat 100% 50%, url('../img/bottle1.png') no-repeat 82% 35%, url('../img/bottle2.png') no-repeat 94% 38%; } }
