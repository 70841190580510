section.reviews {
  margin-bottom: 25px;
  background: $gray_light_color 50% 50% no-repeat;
  background-size: 80%;

  p {
    margin-bottom: 5px; }

  h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: $pink_color;
    padding: 40px 0 25px 0;
    font-weight: 500;
    font-size: 19px; }

  img {
    display: inline; }

  .slider-item {
    p {
      padding: 5px 12px;
      text-align: center; } }

  .reviewer {
    font-weight: bold;
    font-size: 13px; } }

