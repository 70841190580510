.as-item {
  margin-bottom: 30px;
  @media(min-width: $lg) {
    padding: 0 20px; } }

.item {
  margin-bottom: 30px;
  @media(min-width: $lg) {
    padding: 0 20px; }

  &:hover {
    img {
      box-shadow: 0 0 10px #000;
      transform: scale(1.01); } }

  img {
    border: solid 7px #ffffff;
    box-shadow: 0 0 10px #666; } }


.price {
  color: $pink_color;
  line-height: 33px; }
