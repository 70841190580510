$main_color: #333333;
$yellow_color: #FFA724;
$pink_color: #FF62FF;
$gray_color: #AAAAAA;
$gray_light_color: #EEEEEE;
$link_color: #3190dd;

$sm: '540px';
$md: '720px';
$lg: '960px';
$xl: '1200px';
