.thumbnail {
  border-radius: 10px;
  cursor: pointer; }

.pink {
  color: $pink_color; }

.error {
  color: red;
  font-size: 15px; }

.round-thumbnail, .round-thumbnail-big {
  max-width: 130px;
  border-radius: 50%;
  border: solid 7px #ffffff;
  box-shadow: 0 0 10px #666; }

.round-thumbnail-big {
  max-width: 100%;
  margin-bottom: 20px;

  @media(max-width: $sm) {
    max-width: 150px; } }
