body {
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: $main_color; }

select {
  font-family: sans-serif; }

h1 {
  color: $pink_color; }
