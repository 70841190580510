.btn {
    text-transform: uppercase;
    outline: none;
    border: none;
    box-shadow: none;
    &:active, &:focus, &:not(:disabled):not(.disabled):active {
      box-shadow: none; }

    &.btn-primary {
      background-color: $yellow_color;
      &:active, &:focus, &:not(:disabled):not(.disabled):active {
        background-color: $pink_color; } }

    &.btn-success {
      background-color: $pink_color;
      &:active, &:focus, &:not(:disabled):not(.disabled):active {
        background-color: $yellow_color; } } }

.modal {
  .btn {
    font-size: 15px; } }


button.link {
  border: none;
  background: none;
  color: $link_color;
  outline: none;
  cursor: pointer;
  border-bottom: dashed 1px $link_color;
  padding: 0; }

input, textarea, select {
  &.form-control {
    background: #e2eefc; } }

.btn-list, .btn-tile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url("../img/th-large-solid.svg") $yellow_color 50% 50% no-repeat;
  background-size: 50%;
  box-shadow: 0 0 5px #666; }

.btn-list {
  background-image: url("../img/th-list-solid.svg"); }
