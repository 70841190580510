.slick-dots {}
.slick-dots {
  list-style: none;
  text-align: center;
  margin: 0;
  position: absolute;
  bottom: 20px;

  li {
    display: inline-block;
    margin: 0 5px;

    button {
      text-indent: -9000px;
      overflow: hidden;
      padding: 0;
      margin: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: solid 1px $yellow_color;
      outline: none;
      box-shadow: 0 0 5px #000; }

    &.slick-active {
      button {
        background: $pink_color; } } } }
